import React, { useEffect, useState } from "react";

import Checkbox from "@material-ui/core/Checkbox";
import Chip from "@material-ui/core/Chip";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import CancelIcon from "@material-ui/icons/Cancel";

import SButton from "~/components/Buttons/SButton";
import Flex from "~/components/Containers/Flex";
import { SectionHeader } from "~/components/Containers/SpacedPaper";
import Bold from "~/components/Typography/Bold";
import { nurseAPI } from "~/utils/api/v2";
import { Locale } from "~/utils/interfaces/Locale";
import { MedicalActions } from "~/utils/interfaces/models";
import { Item } from "~/utils/interfaces/Services";

type State = {
  id: string;
  names: string;
  last_names: string;
  country: Locale;
};

type NewServicesSelectProps = {
  state: State;
  setLoading: (loading: boolean) => void;
  loading: boolean;
  setError: (error: any) => void;
  medicalServices: Item[];
  medicalActions: MedicalActions[];
  selectedMedicalServices: Item[];
  setSelectedMedicalServices: (selectedMedicalServices: Item[]) => void;
  selectedMedicalActions: MedicalActions[];
  setSelectedMedicalActions: (selectedMedicalActions: MedicalActions[]) => void;
};

function NewServicesSelect({
  state,
  setLoading,
  loading,
  setError,
  medicalServices,
  medicalActions,
  selectedMedicalServices,
  setSelectedMedicalServices,
  selectedMedicalActions,
  setSelectedMedicalActions,
}: NewServicesSelectProps) {
  const [originalMedicalServices, setOriginalMedicalServices] = useState<Item[]>([]);
  const [originalMedicalActions, setOriginalMedicalActions] = useState<MedicalActions[]>([]);

  useEffect(() => {
    if (originalMedicalServices.length === 0) {
      setOriginalMedicalServices(selectedMedicalServices);
    }
    if (originalMedicalActions.length === 0) {
      setOriginalMedicalActions(selectedMedicalActions);
    }
  }, [selectedMedicalServices, selectedMedicalActions]);

  const handleDelete = (event: React.MouseEvent, value: string, type: "medicalservice" | "medicalaction") => {
    const selectedItems = type === "medicalservice" ? selectedMedicalServices : selectedMedicalActions;
    const setSelectedItems = type === "medicalservice" ? setSelectedMedicalServices : setSelectedMedicalActions;
    event.stopPropagation();
    const newSelectedItems = selectedItems.filter((item: Item | MedicalActions) => item.id !== value);
    setSelectedItems(newSelectedItems);
  };

  const handleChange = (event: any, type: "medicalservice" | "medicalaction") => {
    const ids = event.target.value;
    const items = type === "medicalservice" ? medicalServices : medicalActions;
    const setSelectedItems = type === "medicalservice" ? setSelectedMedicalServices : setSelectedMedicalActions;
    const newItems = items.filter((item) => ids.includes(item.id));
    if (type === "medicalservice") {
      setSelectedItems(newItems as Item[]);
    } else {
      setSelectedItems(newItems as MedicalActions[]);
    }
  };

  const handleSubmit = async (type: "medicalservice" | "medicalaction") => {
    setLoading(true);
    try {
      const postRequest = type === "medicalservice" ? nurseAPI.addMedicalServices : nurseAPI.addMedicalActions;

      const deleteRequest = type === "medicalservice" ? nurseAPI.deleteMedicalServices : nurseAPI.deleteMedicalActions;

      const originalData = type === "medicalservice" ? originalMedicalServices : originalMedicalActions;

      const selectedData = type === "medicalservice" ? selectedMedicalServices : selectedMedicalActions;

      const newData = selectedData
        .filter(
          (item: Item | MedicalActions) =>
            !originalData.some((originalItem: Item | MedicalActions) => item.id === originalItem.id),
        )
        .map((item: Item | MedicalActions) => item.id);

      const deletedData = originalData
        .filter(
          (item: Item | MedicalActions) =>
            !selectedData.some((selectedItem: Item | MedicalActions) => item.id === selectedItem.id),
        )
        .map((item: Item | MedicalActions) => item.id);

      await postRequest(state.id, newData);
      await deleteRequest(state.id, deletedData);
    } catch (error) {
      setError(error);
    }
    setLoading(false);
  };

  const medicalServicesHash: Record<string, string> = medicalServices.reduce((acc, medicalService) => {
    acc[medicalService.id] = medicalService.name;
    return acc;
  }, {});

  const medicalActionsHash: Record<string, string> = medicalActions.reduce((acc, medicalAction) => {
    acc[medicalAction.id] = medicalAction.name;
    return acc;
  }, {});

  return (
    <Flex
      direction="column"
      padding="0 2rem 2rem 2rem"
    >
      <SectionHeader>
        <Bold>New Services</Bold>
      </SectionHeader>
      <hr />
      <Bold>Servicios Médicos</Bold>
      <FormControl fullWidth>
        <Select
          MenuProps={{ style: { height: 600 } }}
          multiple
          value={selectedMedicalServices.map(({ id }) => id)}
          onChange={(e) => handleChange(e, "medicalservice")}
          fullWidth
          input={<Input id="select-multiple-chip" />}
          renderValue={(selected) => (
            <Flex wrap="wrap">
              {(selected as string[]).map((value: string) => (
                <Chip
                  variant="outlined"
                  color="primary"
                  key={value}
                  label={medicalServicesHash[value]}
                  clickable
                  deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
                  onDelete={(e) => handleDelete(e, value, "medicalservice")}
                />
              ))}
            </Flex>
          )}
        >
          {medicalServices.map(({ id }, index: number) => (
            <MenuItem
              key={index}
              value={id}
            >
              <Checkbox checked={selectedMedicalServices?.map(({ id }) => id).includes(id)} />
              {medicalServicesHash[id]}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>Servicios Médicos</FormHelperText>
        <center>
          <SButton
            width="20rem"
            variant="outlined"
            color="primary"
            disabled={loading}
            onClick={async () => handleSubmit("medicalservice")}
          >
            Actualizar Servicios
          </SButton>
        </center>
      </FormControl>
      <hr />
      <Bold>Acciones Médicas</Bold>
      <FormControl fullWidth>
        <Select
          MenuProps={{ style: { height: 600 } }}
          multiple
          value={selectedMedicalActions.map(({ id }) => id)}
          onChange={(e) => handleChange(e, "medicalaction")}
          fullWidth
          input={<Input id="select-multiple-chip" />}
          renderValue={(selected) => (
            <Flex wrap="wrap">
              {(selected as string[]).map((value: string) => (
                <Chip
                  variant="outlined"
                  color="primary"
                  key={value}
                  label={medicalActionsHash[value]}
                  clickable
                  deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
                  onDelete={(e) => handleDelete(e, value, "medicalaction")}
                />
              ))}
            </Flex>
          )}
        >
          {medicalActions.map(({ id }, index: number) => (
            <MenuItem
              key={index}
              value={id}
            >
              <Checkbox checked={selectedMedicalActions?.map(({ id }) => id).includes(id)} />
              {medicalActionsHash[id]}
            </MenuItem>
          ))}
        </Select>
        <FormHelperText>Servicios Médicos</FormHelperText>
        <center>
          <SButton
            width="20rem"
            variant="outlined"
            color="primary"
            disabled={loading}
            onClick={async () => handleSubmit("medicalaction")}
          >
            Actualizar Servicios
          </SButton>
        </center>
      </FormControl>
    </Flex>
  );
}

export default NewServicesSelect;
