import Paper from "@material-ui/core/Paper";
import styled from "styled-components";

export const SpacedPaper = styled(Paper)`
  margin: 1rem 0;
`;

export const SectionHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin: 1rem 1rem 0rem 1rem;
`;
